import "./shape-stack-panel.scss";

document.addEventListener("DOMContentLoaded", function () {
  const imageWrapper = document.querySelector(".shape-stack-shapes");
  const overlayImage = document.querySelector(".shape-stack-overlay");

  document.querySelectorAll(".accordion-summary").forEach((summary) => {
    summary.addEventListener("click", () => {
      const currentAccordion = summary.closest(".accordion-details");
      const isOpen = currentAccordion.getAttribute("data-open") === "true";

      // Close all accordions, including the current one if it's open
      document.querySelectorAll(".accordion-details").forEach((accordion) => {
        accordion.setAttribute("data-open", "false");
        accordion
          .querySelector(".accordion-summary")
          .setAttribute("aria-expanded", "false");
      });

      // If the current accordion was not open, open it
      if (!isOpen) {
        currentAccordion.setAttribute("data-open", "true");
        summary.setAttribute("aria-expanded", "true");

        // Handle image activation for the current accordion
        const accordionClassMatch =
          currentAccordion.className.match(/accordion-item-(\d+)/);
        if (imageWrapper && accordionClassMatch) {
          const accordionNumber = parseInt(accordionClassMatch[1], 10);
          const shapeClass = accordionClassMatch[0].replace(
            "accordion",
            "shape",
          );

          imageWrapper
            .querySelectorAll('[data-active="true"]')
            .forEach((image) => {
              image.removeAttribute("data-active");
            });

          const activeImage = imageWrapper.querySelector("." + shapeClass);
          if (activeImage) {
            activeImage.setAttribute("data-active", "true");
          }

          if (overlayImage) {
            overlayImage.setAttribute(
              "data-position",
              accordionNumber % 2 === 0 ? "even" : "odd",
            );
          }
        }
      }
    });
  });
});
